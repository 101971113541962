async function readPhoto(photo) {
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');

    // create img element from File object
    img.src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsDataURL(photo);
    });
    await new Promise((resolve) => img.onload = resolve);

    // draw image in canvas element
    canvas.width = img.width;
    canvas.height = img.height;
    canvas?.getContext('2d')?.drawImage(img, 0, 0, canvas.width, canvas.height);

    return canvas;
}

function scaleCanvas(canvas, scale) {
  const scaledCanvas = document.createElement('canvas');
  scaledCanvas.width = canvas.width * scale;
  scaledCanvas.height = canvas.height * scale;

  scaledCanvas
    .getContext('2d')
    ?.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

  return scaledCanvas;
}

function compressImage(blob, options, fileType, targetFileType) {
  return new Promise((resolve) => {
    const quality = options.quality || 0.8; // Define a qualidade padrão
    const maxSize = options.size * 1024 || blob.size; // Define o tamanho máximo de acordo com a opção size em kilobytes

    const reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Redimensiona a imagem para ficar abaixo do tamanho máximo
        if (blob.size > maxSize) {
          const ratio = Math.sqrt((blob.size / maxSize));
          width = Math.floor(width / ratio);
          height = Math.floor(height / ratio);
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // Converte o canvas para o tipo de imagem especificado, ou para o tipo da imagem original se não houver um tipo de destino especificado
        canvas.toBlob((blob) => {
          resolve(blob);
        }, targetFileType || fileType, quality);
      };
    };
  });
}

export async function resize(file, maxWidth, maxKbSize = 80) {
  let canvas = await readPhoto(file);

  while (canvas.width >= 2 * maxWidth) {
    canvas = scaleCanvas(canvas, .5);
  }

  if (canvas.width > maxWidth) {
    canvas = scaleCanvas(canvas, maxWidth / canvas.width);
  }

  let blob = await new Promise((resolve) => canvas.toBlob(resolve, file.type));

  const kbSize = blob.size / 1024;

  if (kbSize > maxKbSize) {
    blob = await compressImage(blob, { quality: 0.8, size: maxKbSize }, file.type); // 'image/webp'
  }

  return blob;
}

export async function convertToPng(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          const convertedFile = new File([blob], file.name.replace(/\.\w+$/, '.png'), {
            type: 'image/png',
            lastModified: Date.now()
          });
          resolve(convertedFile);
        }, 'image/png');
      };
      img.onerror = () => {
        reject(new Error('Erro ao carregar a imagem'));
      };
      img.src = event.target.result;
    };

    reader.onerror = () => {
      reject(new Error('Erro ao ler o arquivo'));
    };

    reader.readAsDataURL(file);
  });
}
